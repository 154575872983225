import React from "react"

const NotFoundPage = () => {
  return (
    <div>
      "Not Found - TODO"
    </div>
  )
}

export default NotFoundPage